import { checkDefinedAndNotNull } from '@orus.eu/error'
import { Header, UserDropdown, useOpenChat } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { trpcReact } from '../../../../client'
import { useDisconnect } from '../../../../lib/hooks/use-disconnect'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import { useSession } from '../../../../lib/session'
import { usePermissions } from '../../../../lib/use-permissions'

export type SubscriptionV2HeaderProps = {
  compact?: boolean
  progress?: number
  title?: string
}

export const SubscriptionV2Header = memo<SubscriptionV2HeaderProps>(function SubscriptionV2Header({
  compact,
  progress,
  title,
}) {
  const { user } = useSession()
  return (
    <Header
      compact={compact}
      progress={progress}
      initialProgress={20}
      title={title}
      rightBlock={user ? <UserDropdownLoader /> : undefined}
    />
  )
})

const UserDropdownLoader = memo(function UserDropdownLoader() {
  const openChat = useOpenChat()
  const disconnect = useDisconnect()
  const navigateToAccountPage = useNavigateTo({ to: '/' })

  const isCustomerApp = usePermissions().type === 'client'
  if (!isCustomerApp) return null

  const customer = trpcReact.users.loadMyInformation.useQuery()
  if (!customer.data) return null

  return (
    <UserDropdown
      firstName={checkDefinedAndNotNull(customer.data.firstName)}
      lastName={checkDefinedAndNotNull(customer.data.lastName)}
      onOpenChat={openChat}
      onOpenAccountPage={navigateToAccountPage}
      onLogout={disconnect}
    />
  )
})
