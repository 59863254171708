import { css } from '@emotion/react'
import { colorTokens } from '@orus.eu/pharaoh'

/**
 * Level of progress displayed before actually entering the funnel
 */

export const subscriptionStepOuterElementCss = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden scroll;
  background-color: ${colorTokens['color-background']};
`

export const subscriptionStepOuterElementCssMobile = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden scroll;
  background-color: ${colorTokens['color-background']};
`
